<template>
  <base-material-card
    icon="far fa-music"
    title="Bodenmusik"
    color="primary"
  >
    <p>Hierüber werden die gesammelten Bodenmusiken aller Turnerinnen in einer Zip-Datei heruntergeladen. Die Dateien sind nach der eingegebenen Reihenfolge sortiert benannt.</p>
    <v-btn block @click="download">Download</v-btn>
  </base-material-card>
</template>

<script>
import { GRAPHQLserver } from '@/env'

export default {
  name: 'qrcode',

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    }
  },

  computed: {
    baseURL () {
      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/file/music/`
    }
  },

  methods: {
    download () {
      window.open(`${this.baseURL}${this.id}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
